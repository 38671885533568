// @flow
import React from 'react';
import Layout from '../components/layout';
import Link from '../components/link';
import { withFrontMatter } from '../components/front-matter';
import type { FrontMatter } from '../utils/types';
import route from '../routes';

class About extends React.Component<{ frontMatter: FrontMatter }> {
  render() {
    const { frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <p>
          Medium quality canyoneer and web developer. Folds his clothes before
          putting them in his dry bag and has never peed in his wetsuit.
        </p>
        <p>
          American Canyoneering Association Canyon Leader II -{' '}
          <Link to="https://www.canyoneering.net/members/jaredr/">
            View profile on canyoneering.net
          </Link>
        </p>
        <p>
          I help teach canyoneering at the University of Utah.{' '}
          <Link to="https://continue.utah.edu/noncredit/classes?s=canyoneering">
            Sign up
          </Link>{' '}
          for a class if you dare!
        </p>
        <p>
          I'm not above hosting the occasional practice session on{' '}
          <Link to="https://www.facebook.com/groups/canyoneeringutah/">
            Utah Canyoneering Explorers
          </Link>
          .
        </p>
        <p>
          Read about{' '}
          <Link to={route.blog_2012_04_28_my_story}>
            how I got into canyoneering
          </Link>
          ,{' '}
          <Link to={route.blog_2019_06_30_every_mistake}>
            every mistake I've ever made canyoneering
          </Link>
          , and that my greatest strength is that{' '}
          <Link to={route.blog_2019_06_25_worrier}>I worry</Link>.
        </p>
        <p>
          Best way to contact me is through{' '}
          <Link to="https://www.facebook.com/jared.robertson.142">
            facebook
          </Link>{' '}
          or{' '}
          <Link to="http://canyoncollective.com/members/jared-robertson.419/">
            Canyon Collective
          </Link>
          . Don't hesitate to add me as a friend.
        </p>
        <p>I also love to be invited on canyoneering trips :D</p>
      </Layout>
    );
  }
}

export default withFrontMatter('about')(About);
